<template>
    <span>
        <img :src="appLogoImage" width="60" class="mb-75">
        <p class="text-custom-blue-sky pl-1 mb-0 pt-2 d-inline-block" style="font-size: 22px">{{appName}}</p>
    </span>
</template>

<script>
import { $themeConfig } from '@themeConfig'
export default {
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
      appName
    }
  },
}
</script>
